<template>
    <div>
        <div v-if="id">
            <h1>Payment {{ state.object.data.payment_id }}</h1>
            <Details :data="state.object.data" />
            <div v-show='balance != 0.00' class="alert alert-danger" >
                This payment is NOT balanced
            </div>
            <InvoicesAndCredits
                @updateTotals='updateTotals'
                :invoices="state.object.data.invoices"
                :credits="state.object.data.credits"
            />
        </div>

        <New v-else-if="isCurrentView('new')"/>
        
        <Search v-else :showMoreButton="false"/>
    </div>
</template>

<script>
import {store} from "@/store/BusinessObject.store.js";
import {paymentStore} from "./Payment.store.js";
import Search from "@/components/businessObjects/utils/Search";
import Details from "@/components/businessObjects/utils/Details";
import New from "@/components/businessObjects/payment/New";
import InvoicesAndCredits from "./InvoicesAndCredits";

export default {
    name: "Container",
    data() {
        return {
            state: store.state,
            paymentState: paymentStore.state,
            balance: 0
        }
    },
    props: ["id"],
    components: {
        New,
        Search,
        Details,
        InvoicesAndCredits
    },
    created() {
        store.initialize(this.paymentState);
        this.load();
    },
    methods: {
        load: function () {
            if (this.id) {
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([`${this.state.title} ${this.state.object.data.payment_id}`]));
            }
        },
        updateTotals: function (totals) {
            if (totals.creditTotal >= 0) {
                this.state.object.data.credits_total = totals.creditTotal.toFixed(2);
            }
            if (totals.invoiceTotal >= 0) {
                this.state.object.data.invoices_total = totals.invoiceTotal.toFixed(2);
            }
            this.state.object.data.balance_total = ((Math.round((parseFloat(this.state.object.data.amount) + parseFloat(this.state.object.data.credits_total)) * 100) / 100) - this.state.object.data.invoices_total).toFixed(2);
            this.balance = this.state.object.data.balance_total;
        },
        isCurrentView: function (view) {
            return view === this.state.currentView;
        }
    },
    computed: {
        currentView: function () {
            return this.state.currentView;
        }
    },
    watch: {
        id: function () {
            this.load();
        },
        currentView: function() {
            if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>

</style>