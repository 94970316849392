<template>
    <div>
        <h3>Invoices</h3>
        <input type="button" @click="findInvoices" v-show='!searchInvoicesOpen' value="Find Invoices" class="btn btn-default">
        <input v-show="unsavedInvoiceChanges || unsavedCreditChanges" @click="savePaymentsAndCredits" type="button" value="Save Invoices Payments and Credits" class="btn btn-default">
        <div class="table-responsive spacing_top">
            <table class="table">
                <thead>
                <tr>
                    <th>Order</th>
                    <th>Invoice Date</th>
                    <th>Pay</th>
                    <th class="number">Due</th>
                    <th>Amount</th>
                    <th>Discount</th>
                    <th>Early Pmt</th>
                    <th class="number">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(invoice, index) in localInvoices' v-bind:key='index'>
                    <td><router-link :to="`/tracker/${invoice.tracker_id}`" :target="'_blank'">{{ invoice.tracker_id }}</router-link> {{ invoice.cdi_location }}</td>
                    <td>{{  invoice.actual_ship  }}</td>
                    <td>
                        <input @click='queueInvoiceUpdate(invoice)' type='checkbox' v-model='invoice.selected'>
                    </td>
                    <td class="number">${{ invoice.due_amount }}</td>
                    <td class="number">
                        <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input class='amount form-control number' :disabled='!invoice.selected'  @keyup="queueInvoiceUpdate(invoice, false)" type='input' v-model="invoice.amount" style='width: 100px;' >
                        </div>
                    </td>
                    <td class="number">
                        <div class="input-group">
                            <div class="input-group-addon">-$</div>
                            <input class='discount_amount form-control number' :disabled='!invoice.selected' @keyup="queueInvoiceUpdate(invoice, false)" type='input' v-model='invoice.discount_amount'  style='width: 100px;' >
                        </div>
                    </td>
                    <td>{{ invoice.early_pmt }}</td>
                    <td class="number">${{ invoice.total_payment }}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <h3>Credits</h3>
        <div class="cdi_button">
            <input type="button" @click="findCredits" v-show='!searchCreditsOpen' value="Find Credits" class="btn btn-default">
            <div class="table-responsive spacing_top">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Credit</th>
                            <th>Use</th>
                            <th>PO</th>
                            <th class="number">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(credit, index) in localCredits" :key='index'>
                            <td>
                                <router-link :to="`/credit/${credit.credit_id}`" :target="'_blank'">{{ credit.credit_id }}</router-link>
                                {{ credit.cdi_location }}
                            </td>
                            <td>
                                <input @click='queueCreditUpdate(credit)' type='checkbox' v-model='credit.selected'>
                            </td>
                            <td>{{ credit.credit_po }}</td>
                            <td class="number">${{ credit.credit_amount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store'
export default {
    name: 'InvoicesAndCredits',
    props: ['invoices', 'credits'],
    data() {
        return {
            state: store.state,

            localInvoices: [],
            localCredits: [],

            unsavedInvoiceChanges: false,
            unsavedCreditChanges: false,

            invoiceTotal: 0,
            creditTotal: 0,

            searchCreditsOpen: false,
            searchInvoicesOpen: false
        }
    },
    methods: {
        findInvoices: function() {
            if (!this.searchInvoicesOpen) {
                let localThis = this;
                store.api('get_open_invoices').then(function(result) {
                    for (let i = 0; i < result.invoices.length; i++) {
                        localThis.localInvoices.push(result.invoices[i]);
                    }
                });
                this.searchInvoicesOpen = true;
            }
        },
        findCredits: function() {
            if (!this.searchCreditsOpen) {
                let localThis = this;
                store.api('get_open_credits').then(function(result) {
                    for (let i = 0; i < result.credits.length; i++) {
                        localThis.localCredits.push(result.credits[i]);
                    }
                });
                this.searchCreditsOpen = true;
            }
        },
        queueInvoiceUpdate: function(invoice ,toggleCheckbox = true) {
            if (toggleCheckbox) {
                invoice.selected = !invoice.selected;
            }
            this.unsavedInvoiceChanges = true;
            this.getInvoiceTotal();
            this.updateTotals();
        },
        queueCreditUpdate(credit) {
            credit.selected = !credit.selected
            this.unsavedCreditChanges = true;
            this.getCreditTotal();
            this.updateTotals();
        },
        savePaymentsAndCredits() {
            let invoices = [];
            let credits = [];

            for (let i = 0; i < this.localInvoices.length; i++) {
                if (this.localInvoices[i].selected) {
                    invoices.push(this.localInvoices[i]);
                }
            }

            for (let i = 0; i < this.localCredits.length; i++) {
                if (this.localCredits[i].selected) {
                    credits.push(this.localCredits[i]);
                }
            }

            let params = {};
            for (let i = 0; i < invoices.length; i++) {
                params[`params[i][${invoices[i].tracker_id}][tracker_payment_id]`] = invoices[i].tracker_payment_id;
                params[`params[i][${invoices[i].tracker_id}][amount]`] = invoices[i].amount;
                params[`params[i][${invoices[i].tracker_id}][discount_amount]`] = invoices[i].discount_amount;
            }

            for(let i = 0; i < credits.length; i++) {
                params[`params[c][${credits[i].credit_id}]`] = credits[i].credit_amount
            }

            // Emptying the prop array sets up the watcher to assign "Selected" status when the API responds.
            // Also clearing change queues when the update is saved.
            this.localInvoices = [];
            this.localCredits = [];
            this.unsavedInvoiceChanges = false;
            this.unsavedCreditChanges = false;
            this.searchInvoicesOpen = false;
            this.searchCreditsOpen = false;
            store.apiWithObjectLoad('save_order_payments', params)
        },
        getCreditTotal: function() {
            let total = 0;
            if (this.localCredits.length === 0) {
                return total;
            }
            for(let i = 0; i < this.localCredits.length; i++) {
                if (this.localCredits[i].selected) {
                    total += parseFloat(this.localCredits[i].credit_amount);
                }
            }
            this.creditTotal = total
        },
        getInvoiceTotal: function() {
            let paymentTotal = 0;
            let total = 0;
            if (this.localInvoices.length === 0) {
                return total;
            }
            for(let i = 0; i < this.localInvoices.length; i++) {
                if (this.localInvoices[i].selected) {
                    total = parseFloat(this.localInvoices[i].amount) - parseFloat(this.localInvoices[i].discount_amount);
                    paymentTotal += total;
                }
            }
            paymentTotal = Math.round((paymentTotal) * 100 ) / 100;
            this.invoiceTotal = paymentTotal
        },
        updateTotals: function() {
            let totals = {
                invoiceTotal: this.invoiceTotal,
                creditTotal: this.creditTotal
            }

            this.$emit('updateTotals', totals);
        }
    },
    watch: {
        invoices: function() {
            if (this.invoices.length === 0) {
                this.findInvoices();
            } else {
                this.localInvoices = this.invoices
            }
            this.getInvoiceTotal();
            this.updateTotals();
        },
        credits: function() {
            if (this.credits.length === 0) {
                this.findCredits()
            } else {
                this.localCredits = this.credits;
            }
            this.getCreditTotal();
            this.updateTotals();
        },
    }
}

</script>