<template>
    <div class="object_detail">
        <label class="object_detail">
            {{ field.label }}
            <span v-if="field.disableButton && val" class="glyphicon glyphicon-trash cdi_link" @click="disableConfirm">
            </span>
        </label>
        <div class="form-control">
            <ProtectedLink :id="field.linkID" :linkType="'link'" :text="val" :queryString="queryString" :type="'download'" />
        </div>

        <ConfirmBox
            v-if="confirmBox"
            :heading="'Disable?'"
            :body="'Disable file?'"
            @close="disable"
        />
    </div>
</template>

<script>
    import ConfirmBox from "@/components/utils/ConfirmBox";
    import ProtectedLink from "@/components/utils/ProtectedLink";
    import helpers from "@/store/helpers";
    export default {
        name: "DetailsFile",
        components: {ConfirmBox, ProtectedLink},
        props: ['field', 'val'],
        data() {
            return {
                linkPrefix: `${ process.env.VUE_APP_URL }/download/`,
                confirmBox: false
            }
        },
        methods: {
            disableConfirm: function () {
                this.confirmBox = true;
            },
            disable: function (confirmed) {
                if (confirmed) {
                    this.$emit('post', {
                        'field': this.field.name,
                        'val': this.field.linkID,
                        'endPoint': this.field.endPoint
                    });
                }

                this.confirmBox = false;
            }
        },
        computed: {
            queryString: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}`;
            }
        },
    }
</script>

<style scoped>

</style>