<template>
    <div class="object_detail">
        <label class="object_detail">{{ field.label }}</label>
        <div class="form-control">
            <a :href="val" target='_blank'>{{ field.display }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "DetailsExternalLink",
    props: ['field', 'val']
}
</script>

<style scoped>

</style>