<template>
    <div>
        <h1>New Payment</h1>
        <div class="row">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="company" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Check #</label>
                <input type="text" v-model='checkNum' class="form-control">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Amount</label>
                <input type="text" v-model='amount' class="form-control" />
            </div>
        </div>
        <input class='cdi_button btn btn-default' type='button' @click='addPayment' value='Add Payment'>
    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import { store } from '@/store/BusinessObject.store'
export default {
    name: 'New',
    components: {
        Typeahead
    },
    data () {
        return {
            state: store.state,
            company: 0,
            checkNum: '',
            amount: ''
        }
    }, methods: {
        addPayment: function() {
            let localThis = this;
            let params = {
                'params[company_id]': this.company,
                'params[check_num]': this.checkNum,
                'params[amount]': this.amount
            }
            store.api('new_payment', params).then(result => {
                if (result) {
                    this.$router.push({path: `/${localThis.state.nav.base}/${result.payment_id}`});
                }
            });
        }
    }
}

</script>