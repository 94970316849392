<template>
    <div class="object_detail">
        <label class="object_detail_select">{{ field.label }}</label>
        <select v-if="field.valuesOnly === true"
                v-bind="inputAttributes"
                class="cdi_select form-control"
                v-model="localVal"
                @change="save"
        >
            <option v-for="(value, index) in getOptions" v-bind:key="index" :value="value">{{ value }}</option>
        </select>
        <select v-else
                v-bind="inputAttributes"
                class="cdi_select form-control"
                v-model="localVal"
                @change="save"
                :disabled="field.disabled == 1"
        >
            <option v-for="[value, option] in getOptions" v-bind:key="value" :value="value">{{ option }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "DetailsSelect",
    props: {
        'field' : {},
        'val': {},
        'inputAttributes': {},
        'notSetLabel': {
            default: 'Not Set'
        }
    },
    data() {
        return {
            localVal: ''
        }
    },
    methods: {
        save: function() {
            this.$emit('save', {'field': this.field.name, 'val': this.localVal});
        }
    },
    computed: {
        getOptions: function() {
            let options = [];
            if (this.field.display) {
                let optionArr = this.field.display;
                if (this.field.display) {
                    for (let id in optionArr) {
                        options.push([id, optionArr[id]]);
                    }
                }
                if (options.length === 0 || options[0][0] !== 0 && !this.field.excludeDefault) {
                    options.unshift([0, this.notSetLabel]);
                }
            } else {
                options = this.field.options;
                if (this.field.valuesOnly) {
                    return options;
                }
                if (this.field.options[0][0] !== 0 && !this.field.excludeDefault) {
                    options.unshift([0, this.notSetLabel]);
                }
            }
            return options;
        }
    },
    watch: {
        val: {
            immediate: true,
            handler: function () {
                this.localVal = this.val;
            }
        }
    },
}
</script>

<style scoped>
</style>