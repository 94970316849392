<template>
    <div class="panel panel-default">
        <div class="row panel-body details_container">
            <div v-for="field in state.object.fields" v-bind:key="field.name" class="col-lg-4 col-sm-6 col-xs-12">
                <component :is="field.component"
                           :field="fieldData(field)"
                           :val="data[field.name]"
                           @save="save"
                           @post="post"
                           @view="changeView"
                />
            </div>
        </div>
    </div>

</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import DetailsStatic from "./DetailsStatic";
import DetailsFile from "./DetailsFile";
import DetailsLink from "./DetailsLink";
import DetailsText from "./DetailsText";
import DetailsSelect from "./DetailsSelect";
import DetailsDate from "./DetailsDate";
import DetailsCheckbox from "./DetailsCheckbox";
import DetailsTextarea from "./DetailsTextarea";
import DetailsButton from "./DetailsButton";
import DetailsTypeahead from "./DetailsTypeahead";
import DetailsExternalLink from "./DetailsExternalLink";
import DetailsView from "./DetailsView";


export default {
    name: "Details",
    components: {
        DetailsStatic,
        DetailsFile,
        DetailsLink,
        DetailsText,
        DetailsSelect,
        DetailsDate,
        DetailsCheckbox,
        DetailsTextarea,
        DetailsButton,
        DetailsTypeahead,
        DetailsExternalLink,
        DetailsView,
    },
    data() {
        return {
            state: store.state,
            options: [],
            general_ledger_opts:[],
        }
    },
    props: ['data'],
    methods: {
        save: function (data) {
            store.save(data.field, data.val);
        },
        post: function (data) {
            store.api(data.endPoint, {}, 'object.data');
        },
        changeView: function (view) {
            this.state.currentView = view;
        },
        fieldData: function (field) {
            if (field.displayField) {
                this.$set(field, 'display', this.data[field.displayField]);
            }
            if (field.linkField) {
                this.$set(field, 'linkID', this.data[field.linkField]);
            }
            return field;
        },
    },
    mounted: function () {
        if (this.state.name === 'material') {
            store.api('general_ledger_acct_opts', {})
                .then(result => {
                    this.options = result;
                    for (const [key, value] of Object.entries(this.options)) {
                        this.general_ledger_opts[key] = [value.general_ledger_account_id, value.desc];
                    }
                    this.state.object.fields[13].options = this.general_ledger_opts;
                })
        }
    }
}
</script>

<style scoped>

</style>