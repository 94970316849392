<template>
    <div class="object_detail" :title="field.label">
        <label>{{ field.label }}  <span v-if="field.refresh && hasPermission" @click="post" class="glyphicon glyphicon-refresh cdi_link pull-right"></span></label>

        <div class="form-control">
            <span v-if="!val">
                <button class="btn btn-default" @click="post">{{ field.buttonLabel }}</button>
            </span>
            <span v-else-if="field.link">
                <router-link :to="`/${field.link}/${val}`">{{ val }}</router-link>
            </span>
            <span v-else>
                {{ val }}
            </span>
        </div>

    </div>
</template>

<script>
    export default {
        name: "DetailsButton",
        props: ['field', 'val'],
        methods: {
            post: function() {
                if (this.field.endPoint) {
                    this.$emit('post', {
                        'endPoint': this.field.endPoint,
                        'postValue': this.field.postValue
                    });
                }
            },
        },
        computed: {
            hasPermission: function () {
                if (this.field.permission === undefined) {
                    return true;
                } else {
                    return this.field.permission;
                }
            }
        }
    }
</script>

<style scoped>

</style>